import styles from '../../../../styles/Calendar.module.scss';

function StudioSelector({ studio, setStudio, studios, loading, setLoading }) {
	return (
		<select className={styles.studioSelector} value={studio} disabled={loading} onChange={(e) => {
			setStudio(e.target.value);
		}}>
			{studios.map(studio => <option key={studio._id} value={studio._id}>{studio.name}</option>)}
		</select>
	)
}

export default StudioSelector;