import { useContext } from 'react';
import AppContext from "../../context";

import { Helmet } from 'react-helmet';

function Meta({ title }){
	const context = useContext(AppContext);

	return (
		<Helmet>
			<meta charSet="utf-8" />
			<title>{title ? `${title} - ${context.domain.name}` : context.domain.name}</title>
			<link
				rel="shortcut icon"
				type="image/jpg"
				href={`https://surecode.fra1.cdn.digitaloceanspaces.com/${context.domain._id}/favicon.ico`}
			/>
		</Helmet>
	)
}

export default Meta;