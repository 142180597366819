import { useEffect, useState } from 'react';

import Day from "./Day";
import Loading from "../../../components/Loading";

import Data from '../../../../repository';

import styles from '../../../../styles/Calendar.module.scss';

function Sessions({ studio, start, end, loading, setLoading }) {
	const [sessions, setSessions] = useState([]);
	const [localLoading, setLocalLoading] = useState(false);

	useEffect(async () => {
		await setLocalLoading(true)
		await setSessions(await fetchSessions(studio, start, end));
		await setLocalLoading(false);
		await setLoading(false);
	}, [studio, start, end]);

	const fetchSessions = async (studio, start, end) => {
		const result = await Data.fetchSessions(studio, start, end);
		return result.data;
	}

	const days = sessions.reduce((acc, session) => {
		if(!acc.includes(session.start.split("T")[0])) acc.push(session.start.split("T")[0]);
		return acc;
	}, []);

	if(loading || localLoading) return <Loading button={false} />

	return (
		<div className={styles.sessions}>
			{days.map(day => <Day day={day} sessions={sessions} studio={studio} />)}
		</div>
	)
}

export default Sessions;