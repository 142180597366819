import styles from '../../../../styles/Calendar.module.scss';

function WeekSelector({ week, setWeek, loading }) {

	const decrement = async () => {
		await setWeek(week - 1 < 0 ? 0 : week - 1)
	}

	const increment = async () => {
		await setWeek(week + 1 > 3 ? 3 : week + 1)
	}

	return (
		<div className={styles.weekSelector}>
			<button className="btn btnEmpty" onClick={decrement} disabled={(week < 1) || loading}>Prev</button>
			<button className="btn btnEmpty" onClick={increment} disabled={(2 < week) || loading}>Next</button>
		</div>
	)
}

export default WeekSelector;