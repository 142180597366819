import { useState } from 'react';
import { Capacitor } from "@capacitor/core";

import StudioSelector from "./StudioSelector";
import WeekSelector from "./WeekSelector";
import Sessions from "./Sessions";
import Header from "../../../components/Header";

import styles from '../../../../styles/Calendar.module.scss';

function Calendar({ studio, setStudio, week, setWeek, studios }) {
	const [loading, setLoading] = useState(true);

	let today = new Date();
	today = new Date(today.setHours(0, 0, 0, 0));

	let monday = today.getDate() - ((today.getDay() + 6) % 7);

	const start = new Date(today.setDate(monday + (7 * week)));
	const end = new Date(today.setDate(start.getDate() + 7));

	return (
		<>
			{Capacitor.isNativePlatform() && <Header heading={"Create Booking"} />}
			<div className={styles.calendar}>
				<div className={styles.controls}>
					<StudioSelector studio={studio} setStudio={setStudio} studios={studios} loading={loading} />
					<WeekSelector week={week} setWeek={setWeek} loading={loading} />
				</div>
				<Sessions studio={studio} start={start} end={end} loading={loading} setLoading={setLoading} />
			</div>
		</>
	)
}

export default Calendar;