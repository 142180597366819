import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import Loading from "../../components/Loading";
import Meta from '../../components/Meta';
import AppContext from "../../../context";

import Data from '../../../repository';

import styles from "../../../styles/Entry.module.scss";

function CreateAccount() {
	const context = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		email: "",
		password: "",
		firstName: "",
		lastName: "",
		phoneNumber: "",
		subscribe: false,
		error: null
	});

	const onChange = (event) => {
		console.log(event)
		setState({
			...state,
			[event.target.name]: event.target.name === "subscribe" ? event.target.checked : event.target.value
		})
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		try {
			await Data.createAccount(state);
			const result = await Data.login(state.email, state.password);
			localStorage.setItem("token", result.data.token);
			window.location.reload();
		} catch(error) {
			setState({
				...state,
				error: error.response.data
			})
		} finally {
			setLoading(false);
		}
	}

	console.log(state)

	return (
		<>
			<Meta title="Create Account" />

			<div>
				{state.error &&
				<div className={styles.error}>
					<p>{state.error.message}</p>
				</div>
				}

				<h1>Create Account</h1>

				<form onSubmit={onSubmit}>
					<input type="email" name="email" placeholder="Email Address" value={state.email} onChange={onChange} maxLength={255} required={true} />
					<input type="password" name="password" placeholder="Password" value={state.password} onChange={onChange} maxLength={255} required={true} />
					<input type="text" name="firstName" placeholder="First Name" value={state.firstName} onChange={onChange} maxLength={255} required={true} />
					<input type="text" name="lastName" placeholder="Last Name" value={state.lastName} onChange={onChange} maxLength={255} required={true} />
					<input type="text" name="phoneNumber" placeholder="Phone Number" value={state.phoneNumber} onChange={onChange} maxLength={255} required={true} />
					<label className={styles.checkbox}>
						<input type={"Checkbox"} checked={state.subscribe} onChange={onChange} name={"subscribe"} />
						Receive news and updates about {context.domain.name}.
					</label>
					<button className="btn btnPrimary" disabled={loading}>
						{loading ?
							<Loading button={true} />
							:
							`Create Account`
						}
					</button>
				</form>

				<div className={styles.alternatives}>
					<Link to="/entry/login">Already have an account?</Link>
				</div>
			</div>
		</>
	);
}

export default CreateAccount;
